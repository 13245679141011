import React from 'react'
import DarkButton from "./DarkButton";
import Modal from "./Modal";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const BlogComponent = ({data}) => (
    <Link
        to={data.url}
        target='_blank'
        rel="noreferrer"
    >
    <div
        className="flex transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex-col rounded-2xl shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-900 p-6 flex flex-col justify-between">
            <div className="flex-1">
                <div><h3
                    className="mt-1 text-xl leading-7 font-semibold text-gray-200">
                    {data.name}
                </h3> <p className="mt-3 text-sm leading-6 text-gray-400">
                    {data.description}
                </p></div>
            </div>
            <div className="mt-6 flex items-center justify-start gap-4">
                {
                    data.demo ?
                        <DarkButton url={data.demo} target="_blank" ref="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="gray">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"/>
                            </svg>
                            &nbsp;
                            Demo
                        </DarkButton> : null
                }

                {
                    data.repo ? <DarkButton url={data.repo} target="_blank" noreferrer>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="gray">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                        </svg>
                        &nbsp;
                        Repo
                    </DarkButton> : null
                }

                {
                    data.details ?
                        <Modal/> : null

                }
            </div>
            <div className="mt-2 flex items-center">
                <div className="flex-shrink-0"><a target="_blank" rel="noreferrer"
                                                  href="https://github.com/pratikdaigvane">
                    <StaticImage
                        src="../images/avatar.jpeg"
                        placeholder="none"
                        alt="Pratik Daigavane"
                        quality={70}
                        className="h-9 w-9 rounded-full"
                    />
                </a></div>
                <div className="ml-3"><p
                    className="text-xs font-medium text-gray-200"><a
                    target="_blank" rel="noreferrer" href="https://github.com/pratikdaigvane"
                    className="hover:text-indigo-600">
                    Pratik Daigavane
                </a></p>
                    <div className="flex text-xs text-gray-300">
                        {data.date}
                        <span className="mx-1">
              ·
            </span> <span>
              {data.readTime} min read
            </span></div>
                </div>
            </div>
        </div>
    </div>
    </Link>
)

export default BlogComponent
import React from "react"
import Typical from 'react-typical'
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import Recommendations from "../containers/Recommendations";
import Projects from "../containers/Projects";
import Blogs from "../containers/Blogs";

const getCompanyLogos = (companies) => {
    return companies.map((item) => (
        <div className="flex justify-center items-center"><a target="_blank" rel="noreferrer" href={item.url}>
            <GatsbyImage
                image={getImage(item.logo)}
                alt={item.name}
                placeholder="none"
                imgClassName="p-1"
                className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 w-auto hover:text-indigo-600 h-auto select-none"
            />
        </a>
        </div>
    ))
}

const Index = () => {
    const data = useStaticQuery(graphql`query HomePageDataQuery {
        contentJson {
            subtitle
            aka
            name
        }
        allCompaniesJson {
            nodes {
                name
                logo {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                url
            }
        }
    }
    `)
    return (
        <>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div className="px-4 flex flex-col justify-start md:justify-center lg:justify-start">
                    <h1 className="pb-3 mt-4 text-4xl tracking-tight leading-10 font-extrabold text-white sm:mt-5 sm:leading-none sm:text-5xl lg:mt-6 lg:text-5xl xl:text-5xl">
                        {data.contentJson.name}
                    </h1>
                    <h3 className="text-gray-200 text-3xl font-extrabold lg:text-4xl pb-5">aka {data.contentJson.aka}</h3>
                    <div className="text-indigo-600 font-extrabold text-2xl mt-5 leading-5 skills h-5 md:text-3xl">
                        <Typical
                            steps={[
                                "Full Stack Developer🚀", 1500,
                                "Cloud and DevOps🌩️", 1500,
                                "System Design ‍💻", 1500
                            ]}
                            loop={Infinity}
                            wrapper="h2"
                        />
                    </div>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        {data.contentJson.subtitle}
                    </p>
                    <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">Worked at</p>
                    <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                        <div className="grid grid-cols-2 gap-20">
                            {getCompanyLogos(data.allCompaniesJson.nodes)}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-4">
                    <div className="px-14">
                        <StaticImage
                            src="../images/banner.svg"
                            placeholder="none"
                            alt="Header"
                            quality={70}
                        />
                    </div>
                </div>
            </div>
            <Projects/>
            <Recommendations/>
            <Blogs/>
        </>
    )
}

export default Index

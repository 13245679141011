import React from 'react';

function DarkButton({url, children, ...otherProps}) {
    return (

        <a href={url}
           className="inline-flex cursor-pointer items-center px-3 py-1 border border-transparent text-xs rounded-md text-white bg-gray-700 hover:text-white hover:bg-indigo-700 focus:outline-none focus:shadow-outline-gray focus:border-indigo-600 active:bg-indigo-600 transition duration-150 ease-in-out" {...otherProps}>
            {children}
        </a>
    );
}

export default DarkButton;